//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';

import sharedVariables from '~/util/shared-variables.js';
import ArrowRight from '~/static/svg/arrow-right.svg';
import ArrowLeft from '~/static/svg/arrow-left.svg';

import gtmMixin from '~/mixins/gtm';
import algoliaRecommend from '~/mixins/algoliaRecommend';

export default {
  mixins: [gtmMixin, algoliaRecommend],
  components: {
    ArrowLeft,
    ArrowRight,
  },
  props: {
    relatedProducts: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      default: 'related',
      required: false,
    },
    variations: {
      type: Array,
    },
    selectedObjectId: {
      required: false,
    },
    pageType: {
      type: String,
      default: 'blog',
    },
    autoplaySpeed: {
      type: String,
      default: '4000',
    },
    title: {
      type: String,
      default: 'Related Products',
    },
    objectIDs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    componentClasses() {
      let classes = [];
      if (this.isBlogPage) classes.push('blog-page');
      else if (this.isCheckoutPage) classes.push('checkout-page');

      return classes.join(' ');
    },
    isBlogPage() {
      return this.pageType === 'blog';
    },
    isCheckoutPage() {
      return this.pageType === 'checkout';
    },
  },
  data() {
    return {
      isLoaded: false,
      isFetchingPrice: false,
      products: [],
      options: { autoplaySpeed: undefined },
      productsRaw: [],
      recommendationsRaw: [],
    };
  },
  // vue-slick-carousel autoplay is causing error (keep refering to undefined variable)
  // we have to manage the autoplay in this component with the updated() and beforeDestroy()
  updated() {
    if (this.isLoaded && this.$refs['slick-carousel'] && !this.isCheckoutPage) {
      // this.$refs['slick-carousel'].autoplay = true; // turn on autoplay
      this.slickCarousel = this.$refs['slick-carousel']; // keep a handle on this carousel
    }
  },
  beforeDestroy() {
    if (this.isLoaded && this.slickCarousel) {
      this.slickCarousel.pause(); // stop the autoplay on this carousel before destroying
    }
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
    }),
    environmentPdpUrl(product) {
      const envBaseUrl = this.$config.baseUrl;
      return `${envBaseUrl}/p/${product.slug}/`;
    },
    async addAlgoliaRecommendationsToList() {
      let recommendations = [];
      const records = [];
      if (!this.selectedObjectId) return [];

      const args = {
        products: this.productsRaw,
        variationIds: this.getVariationIds(),
        params: {
          listLimit: 24,
          objectID: this.selectedObjectId, //ObjectID of current product/variations
        },
      };

      recommendations = await this.getProductRecommendations(args);
      if (recommendations?.length > 0) {
        this.recommendationsRaw = recommendations;
        //Fill Related Products with Recommendations up to List Limit
        for (const recommendedProduct of recommendations) {
          records.push(recommendedProduct);
        }
      }

      return records;
    },
    async addAlgoliaBoughtTogetherList(objectIDs) {
      let recommendationResults = [];
      const recommendations = [];
      const totalCarouselItems = 24
      //this.$config.frequentlyBoughtTogether.listLimit;

      const args = {
        listLimit: totalCarouselItems,
        objectIDs,
      };

      recommendationResults = await this.getAlgoliaRecommendations(
        'together',
        args
      );

      if (recommendationResults?.length > 0) {
        this.recommendationsRaw = recommendationResults;
        //Fill Related Products with Recommendations up to List Limit
        for (const recommendedProduct of recommendationResults) {
          if (recommendations.length >= totalCarouselItems) break;
          recommendations.push(recommendedProduct);
        }
      }

      this.products = recommendations;
    },
    async gtmOnMount() {
      const gtmProducts = [];
      if (!this.productsRaw.length) return;
      for (const [index, prod] of this.productsRaw.entries()) {
        if (
          !prod.categories ||
          (Array.isArray(prod.categories) && prod.categories.length === 0) ||
          Object.keys(prod.categories).length === 0
        ) {
          continue;
        }

        const category = await this.getCategory(prod.categories);
        const list = this.getPathName;

        gtmProducts.push({
          name: prod.content.title,
          id: prod.id,
          category,
          position: index + 1,
          list,
        });
      }

      if (gtmProducts.length) {
        this.triggerProdCardsImpressionView(gtmProducts);
      }
    },
    setSliderOptions() {
      if (this.isCheckoutPage) {
        this.options = {
          dots: true,
          infinite: false,
          lazyLoad: true,
          initialSlide: 0,
          speed: 1000,
          slidesToScroll: 4,
          slidesToShow: 4,
          autoplay: false,
          pauseOnHover: true,
          pauseOnDotHover: true,
          variableWidth: true,
          swipeToSlide: true,
          centerMode: false,
          arrows: true,
          touchThreshold: 3,
          responsive: [
            {
              breakpoint: sharedVariables.sm_max,
              settings: {
                slidesToScroll: 2,
                slidesToShow: 2,
              },
            },
          ],
        };
        return;
      }

      this.options = {
        dots: true,
        infinite: false,
        initialSlide: 0,
        speed: 1000,
        autoplay: false,
        lazyLoad: true,
        slidesToScroll: 4,
        slidesToShow: 4,
        // autoplaySpeed: parseInt(this.autoplaySpeed),
        pauseOnHover: true,
        pauseOnDotHover: true,
        variableWidth: true,
        swipeToSlide: true,
        arrows: true,
        responsive: [
          {
            breakpoint: sharedVariables.sm_max,
            settings: {
              centerMode: true,
              centerPadding: '30px',
            },
          },
        ],
      };
    },
    getImageSet(product) {
      const images = product.images;
      const productId = product.id.toLowerCase();
      let imageSet = [];

      if (
        images.variations[productId] &&
        images.variations[productId].length > 0
      ) {
        imageSet = images.variations[productId];
      } else if (images.main && images.main.length > 0) {
        imageSet = images.main;
      } else {
        for (const variation in images.variations) {
          if (images.variations[variation].length > 0) {
            imageSet = images.variations[variation];
            break;
          }
        }
      }

      return imageSet;
    },
    getVariationIds() {
      if (!this.variations) return [];

      let variationIds = [];
      this.variations.forEach(variant => {
        variationIds.push(variant.id);
        if (variationIds.indexOf(variant.sku) < 0) {
          variationIds.push(variant.sku);
        }
      });

      return variationIds;
    },
    createBasePricingObject(product, sku) {
      let basePricing = {};
      if (product.basePricing) {
        // web product range data (cheapest/highest skus)
        basePricing = { ...product.basePricing };
      }

      // this is a sku level record, check variants for price data
      const thisVariant = product.variations.find(
        variant => variant.sku === sku
      );

      if (thisVariant) {
        // retail sku price
        if (
          thisVariant.defaultPricePlusGst &&
          !isNaN(thisVariant.defaultPricePlusGst) &&
          thisVariant.defaultPricePlusGst > 0
        ) {
          basePricing.skuRetailIncGst = thisVariant.defaultPricePlusGst;
        }

        // if this variant has bulk discounts, add base pricing flag
        if (
          thisVariant.volumeDiscounts &&
          thisVariant.volumeDiscounts.length > 0
        ) {
          basePricing.hasBulkDiscount = true;
        }
      }

      if (basePricing.skuRetailIncGst || basePricing.webProductLowest?.price) {
        return basePricing;
      }

      return undefined;
    },
    createDefaultProductRecord(product, id) {
      // console.log('create default product record', product);
      // get default variant
      let variant = product.variations[0];
      if (!variant) return;

      const newRecord = {
        title:
          product.content && product.content.title ? product.content.title : '',
        url: '/p/' + product.slug,
        slug: product.slug,
        imageId: this.getImageSet(product)[0],
        id,
        sku: variant.sku,
        categories: product.categories,
        priceQuery: product.priceQuery,
        limitedStock: product.limitedStock,
        specialOrder: product.specialOrder,
      };

      const basePricing = this.createBasePricingObject(product, variant.sku);
      if (basePricing) newRecord.basePricing = basePricing;

      return newRecord;
    },
    async createDefaultProductRecords() {
      const defaultRecords = [];
      let manualLimit = this.$config.relatedProducts.manualLimit;
      //Add to Related Products from Manually Entered Recommendations
      for (const id of this.relatedProducts) {
        if (this.productsRaw.length >= manualLimit) break;
        const relatedProduct = await this.getProductById({ id });
        if (!relatedProduct) continue;

        this.productsRaw.push(relatedProduct);
        const newItem = this.createDefaultProductRecord(relatedProduct, id);
        if (newItem) defaultRecords.push(newItem);
      }

      return defaultRecords;
    },
    async buildRelatedProductResults() {
      // This Products Recommendation from business logic
      const defaultRecords = await this.createDefaultProductRecords();
      
      // This Products Recommendation from Algolia Recommended Model
      const algoliaRecords = await this.addAlgoliaRecommendationsToList();
      
      // Merge List, Business recommendations take priority
      const records = [...defaultRecords, ...algoliaRecords];
      
      // limit results to x amount
      const trimmedRecords = records.slice(
        0,
        this.$config.relatedProducts.carouselLimit
      );

      this.products = trimmedRecords;
    },
  },
  async mounted() {
    this.setSliderOptions();

    // Retrieve Recommendations from Algolia,
    // append to rest of related products list
    if (this.type === 'together') {
      await this.addAlgoliaBoughtTogetherList(this.objectIDs);
      this.isLoaded = true;
      return;
    }

    await this.buildRelatedProductResults();
    this.isLoaded = true;
    this.gtmOnMount();
  },
};
